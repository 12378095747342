import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import moment from "moment";

export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
}) => {
  const { contentType, method, endPoint, body, token } = data;

  const header: any = {
    token: token ? token : localStorage.getItem("authToken"),
  };
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const mockAPICall = (
  instance: any,
  apiCallID: string,
  apiData: object
) => {
  const msgSucessRestAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgSucessRestAPI.messageId
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    apiData
  );
  instance[apiCallID] = msgSucessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSucessRestAPI);
};

export interface RecentPackages {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}

interface SubscriptionAttributes {
  id: number;
  account_id: number;
  coach_id: number;
  package_id: number;
  start_date: string;
  end_date: string;
  sessions_remaining: number;
  no_shows_remaining: number;
  freeze_times_allowed: number;
  order_id:string;
  freeze_days_allowed: number;
  freeze_times_used: number;
  is_frozen: boolean;
  freeze_start_date: string | null;
  status: string;
  final_status: string;
  final_price: number;
  includes_service: string[];
  payment_details: {
		wallet_used: number;
		discount: number;
    amount_paid: string;
    invoice_url:null|string;
		coupon: {
			id: number;
      max_use_per_user: number;
			coupon_description: string;
			coupon_name: string;
			start_date: string;
			coupon_type: string;
			maximum_usage_limit: string;
			end_date: string;
			created_at: string;
			coupon_code: string;
			usage_left: number;
			updated_at: string;
			flat_discount: number | null;
			discount_percentage: number;
		}
  };
  package: PackageData;
  coach: CoachData;
}

interface PackageData {
  data: PackageAttributes;
}

interface PackageAttributes {
  id: string;
  type: string;
  attributes: {
    id: number;
    package_name: string;
    duration: string;
    start_date: string;
    end_date: string;
    price: number;
    discount_price: number;
    no_of_session: number;
    no_of_freezing: string;
    no_of_no_show: string;
    note: string;
    created_at: string;
    freeze_allowed: number;
    freeze_days_allowed: number;
    updated_at: string;
    includes_service: any[];
    final_price: number;
  };
}

interface CoachData {
  data: CoachAttributes;
}

interface CoachAttributes {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    user_bio: string;
    full_phone_number: string;
    email: string;
    gender: string;
    coach_languages: string[];
    country_name: string;
    dob: string;
    city: string;
    profile_pic: string;
    icebreaker_answers: {
      describe_coaching_style_in_3_words: string;
      recently_did_or_read: string;
      accoplishment: string;
    };
    social_profile: {
      facebook_url: string;
      linkedin_url: string;
      twitter_url: string;
      instagram_url: string;
    };
    availability_status: boolean;
    chemistry_call_charge: number;
  };
}

export const redirectToPage = (navigation: any, path: string) => {
  navigation.navigate(path);
};

export const checkTimeDifference = (event_time: string) => {
  const currentTimeUTC = moment().utc();

  const eventTimeUTC = moment.utc(event_time);

  const timeDifference = eventTimeUTC.diff(currentTimeUTC, "hours");

  return timeDifference < 48 ? true : false;
};

export const calculateCalculateNo = (
  no_of_session: number,
  sessions_remaining: number
) => {
  return no_of_session - sessions_remaining;
};

export const formatNumber = (num: number) => {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num ? num.toFixed(2) : num;
  }
};
export const calculatePercentage = (
  no_of_session: number,
  sessions_remaining: number
) => {
  const completedSession = calculateCalculateNo(
    no_of_session,
    sessions_remaining
  );
  return formatNumber((completedSession / no_of_session) * 100);
};

export const packageStatus = (status: string) => {
  if (status === "completed") {
    return {
      backgroundColor: "#6200EA",
    };
  } else if (status === "active") {
    return { backgroundColor: "#9F6CEF" };
  } else {
    return {
      backgroundColor: "white",
      border: "1px solid #390689",
      color: " #390689",
    };
  }
};

export const renderBookSessionErrorForClient = (coachDetails: {
  id: number | string;
  full_name: string;
  value?: string;
  email: string;
  url: string;
  has_package: boolean;
  is_frozen: boolean;
}) => {
  if (!coachDetails.has_package) {
    return "There is no active package to book a session.";
  }
  if (coachDetails.is_frozen) {
    return "Your package has been frozen. Kindly unfreeze it to book a session.";
  }
  return "";
};

export const renderBookSessionErrorForCoach = (clientDetails: {
  id: number | string;
  full_name: string;
  value?: string;
  email: string;
  has_package: boolean;
  is_frozen: boolean;
  chemistry_session_calendly_url:string
}) => {
  if (!clientDetails.has_package) {
    return "There is no active package to book a session.";
  }
  if (clientDetails.is_frozen) {
    return "This client has frozen the package, sessions cannot be booked at this moment.";
  }
  return "";
};