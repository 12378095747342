import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import ReactDOM from "react-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from 'yup';
import {logo, coachyLogo} from "./assets";
import { firstScreen,secondScreen,thirdScreen } from "./assets";
import { redirectToPage } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  children?: React.ReactNode | React.ReactNode[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hideTop: any;
  name: string;
  email: string;
  number: string;
  description: string;
  captchaDone: string | null;
  key: string;
  showSuccessModal: boolean;
  showSuccessModalSec: boolean;
  lastActivity: number;
  isVisible: boolean;
  videoDis: any;
  currentIndex: number;
  currentText: string;
  isDeleting: boolean;
  dropMenuState: boolean;
  scrolling: boolean;
  scrollNavbar:boolean;
  carouselCurrentId:number;
  carouselData:any;
  direction:string;
  mobileNavigation: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scrollRefServices: RefObject<HTMLLabelElement>;
  scrollRefWorks: RefObject<HTMLLabelElement>;
  scrollRefNavbar: RefObject<HTMLLabelElement>;
  positionRefWorks: RefObject<HTMLDivElement>;
  scrollNavbarRef: RefObject<HTMLDivElement>;
  contactUsApiCallId: string = "";
  captcha: RefObject<HTMLDivElement>;
  intervalId: NodeJS.Timeout | null = null;
  contactUsRef: RefObject<HTMLDivElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.captcha = React.createRef();
    this.scrollRefServices = React.createRef();
    this.scrollRefWorks = React.createRef();
    this.scrollRefNavbar = React.createRef();
    this.positionRefWorks = React.createRef();
    this.scrollNavbarRef = React.createRef();
    this.contactUsRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      hideTop: false,
      name: "",
      email: "",
      number: "",
      description: "",
      captchaDone: "",
      key: "6Lc_nxkpAAAAANchHOw1ALlij1vtbB8GM22cPGc-",
      showSuccessModal: false,
      showSuccessModalSec: false,
      lastActivity: new Date().getTime(),
      isVisible: false,
      currentIndex: 0,
      currentText: "",
      isDeleting: false,
      dropMenuState: false,
      videoDis:"https://cdn.arabsstock.com/uploads/videos/117832/thumbnail_3uUHHa3dpnBI4Ev4l9Ls.mp4",
      scrolling: false,
      scrollNavbar:false,
      carouselData:[
        {
          id:1,
          image:firstScreen,
          title:"Our coaching technology is designed to simplify your daily tasks",
          description:"allowing you to focus on making greater impact. With integrated assessments, automated reminders, and recurring feedback, our management tools streamline your coaching process and make your work easier."
          ,isOpen:true,
        },{
          id:2,
          image:secondScreen,
          title:"Our platform empowers you to make a greater impact while maintaining",
          description:"flexibility and autonomy. You have the freedom to set your preferred schedule, and we connect you with members from diverse backgrounds across 19+ industries and various levels.This enables you to expand your reach and make a difference in the lives of individuals around the world."
          ,isOpen:false,
        },{
          id:3,
          image:thirdScreen,
          title:"COACHY is fueled by some of the most brilliant minds in coaching.",
          description:"As a COACHY user, you will have exclusive access to cutting-edge insights, proprietary assessments, and the latest evidence-based methods through COACHY Labs. This unique feature sets us apart from other coaching platforms and provides you with a significant advantage in achieving your goals."
          ,isOpen:false
        }
      ],
      carouselCurrentId:0,
      direction:"null",
      mobileNavigation: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.response_SucessCell(responseJson);
        }
      }
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let CoachID = message.getData(getName(MessageEnum.page));
      if(CoachID == 2){
        this.handleWorks();
      } else if(CoachID == 3){
        this.handleServices();
      }
      else if(CoachID==4){
        this.navigateToContactusPage();
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleLoginNavigate = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  handleRegisterNavigate = () => {
    this.props.navigation.navigate("CoachLandingPage");
  };
  handleForIndivisual = () => {
    this.props.navigation.navigate("ExecutiveAccountCreation");
  };

  hideTopBar = () => {
    this.setState({ hideTop: true });
  };

  validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .trim(),
    fullName: Yup.string()
      .required("Full Name is required")
      .trim(),
    phoneNumber: Yup.string()
      .matches(/^[1-9]\d{5,15}$/, "Invalid phone number")
      .required("Phone Number is required")
      .trim(),
    description: Yup.string().trim(),
    captchaValue: Yup.string()
      .nullable()
      .trim()
      .required("Captcha verification is required"),
  });

  individualHandleBtn = () => {
    const message: Message = new Message(
      getName(MessageEnum.ExcecutiveAccountCreationScreen)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.stepper), "0");
    this.send(message);
  };
  response_SucessCell = (responseJson: any) => {
    if (responseJson.data) {
      //@ts-ignore
      this.captcha?.current?.reset();
      this.setState({
        name: "",
        email: "",
        number: "",
        description: "",
        captchaDone: "",
        showSuccessModal: true,
      });
    }
  };
 
  contactUsLanding = (value: {
    fullName: string;
    email: string;
    description: string;
    phoneNumber: string;
    captchaValue: string | null;
  }) => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };
    this.setState({
      name: value.fullName,
      email: value.email,
      number: value.phoneNumber,
      description: value.description,
      captchaDone: value.captchaValue,
    });
    const data = {
      data: {
        name: value.fullName,
        email: value.email,
        phone_number: value.phoneNumber,
        description: value.description,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_us/contacts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.contactUsApiCallId;
  };
  handleNavBarScroll=()=>{
    const scrollTop = this.scrollRefNavbar.current?.getBoundingClientRect().top || window.screenY;
    if(scrollTop <= 0){
      this.setState({
        scrollNavbar:true
      })
    } else if(scrollTop>0){
      this.setState({
        scrollNavbar:false
      })
    }
  }

  async componentDidMount() {
    document.addEventListener('mousemove', this.handleActivity);
    document.addEventListener('keydown', this.handleActivity);
    this.intervalId = setInterval(this.checkInactivity, 1000);
    window.addEventListener('scroll', this.handleNavBarScroll);
    this.type();
    const rootNode = ReactDOM.findDOMNode(this) as Element;

    if (rootNode) {
      rootNode.scrollTop = 0;
    }
  }

  async componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleActivity);
    document.removeEventListener('keydown', this.handleActivity);
    clearInterval(this.intervalId as NodeJS.Timeout);
    const rootNode = ReactDOM.findDOMNode(this) as Element;

    if (rootNode) {
      rootNode.scrollTop = 0;
    }
  }
  
  // async componentWillUnmount() {
  //   window.removeEventListener('scroll',this.handleNavBarScroll);
  // }

  handleActivity = () => {
    this.setState({ lastActivity: new Date().getTime() });
  };
  checkInactivity = () => {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - this.state.lastActivity;
    if (timeElapsed > 120 * 1000) { // 120 seconds
      this.setState({ showSuccessModalSec: true });
    }
  };

  handleModalBackClick = () => {
    this.setState({ showSuccessModal: false });
  };
  handleModalBackClickSec = () => {
    this.setState({ showSuccessModalSec: false });
  };

  navigateToCoachPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CoachLandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToContactusPage = () => {
    if (this.contactUsRef.current) {
      this.setState({ showSuccessModalSec: false })
      this.contactUsRef.current.scrollIntoView({ behavior: "smooth", inline: "nearest" })
    }
  }

  navigateToExecutiveCreationPage = () => {
    this.props.navigation.navigate("ExecutiveAccountCreation")
  }

  scrollToTop = () => {
      const rootNode = ReactDOM.findDOMNode(this) as Element ;
      if (rootNode) {
        rootNode.scrollTo({ top: 0, behavior: "smooth" });
      }
  };

  handleServices = () => {
    if (this.scrollRefServices.current) {
      const rootNode = ReactDOM.findDOMNode(this) as Element;

      if (rootNode) {
        rootNode.scrollTo({ top: 850, behavior: "smooth" });
      }
    }
  };

  handleWorks = () => {
    if (this.scrollRefWorks.current) {
      const rootNode = ReactDOM.findDOMNode(this) as Element;


      if (rootNode) {
        rootNode.scrollTo({ top: 3050, behavior: "smooth" });
      }
    }
  }

  strings = [
    "ACCESS MORE CUSTOMERS",
    "OPTIMIZE ADMIN WORK",
    "GROWTH THEIR IMPACT",
  ];

  type = () => {
    const currentString = this.strings[this.state.currentIndex];
    if (
      !this.state.isDeleting &&
      this.state.currentText.length < currentString.length
    ) {
      this.setState({
        currentText: currentString.substring(
          0,
          this.state.currentText.length + 1
        ),
      });
    } else if (this.state.isDeleting && this.state.currentText.length > 0) {
      this.setState({
        currentText: this.state.currentText.substring(
          0,
          this.state.currentText.length - 1
        ),
      });
    } else {
      this.setState({
        isDeleting: !this.state.isDeleting,
      });
      if (!this.state.isDeleting) {
        this.setState({
          currentIndex: (this.state.currentIndex + 1) % this.strings.length,
        });
      }
    }
    setTimeout(this.type, 300);
  };

  dropMenus = () => {
    this.setState({
      dropMenuState: true,
    });
  };

  dropMenusCollapse = () => {
    this.setState({
      dropMenuState: false,
    });
  };

  handleNavBarScrollColor=()=>{
    const scrollTop = this.scrollNavbarRef.current?.getBoundingClientRect().top || window.screenY;
    if(scrollTop <= 0){
      this.setState({
        scrolling:true
      })
    } else if(scrollTop>0){
      this.setState({
        scrolling:false
      })
    }
  }

  returnSrc = () => {
    return !this.state.scrolling ? logo : coachyLogo
  }

  returnServiveClassName = () => {
    return !this.state.scrolling ? "service_dropdown service_dropdown_bg_purple" : "service_dropdown service_dropdown_bg_white"
  }

  mobileNavigationHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState({ mobileNavigation: !this.state.mobileNavigation });
  }

  showMobileNavigationIcons = () => {
    return this.state.dropMenuState ? <span onClick={this.dropMenusCollapse}><CloseIcon /></span> : <span data-testId="dropDownID" onClick={this.dropMenus}><MenuIcon /></span>
  }

  hoverDivClass = () => {
    return this.state.scrolling ? `bg_white` : `bg_purple`
  }

  mainLoginClass = () => {
    return this.state.scrolling ? "landing-page-button scrolled_login_slide login_purple" : "landing-page-button login_slide login_white"
  }

  redirectToComingSoonPage = ()=>{
    redirectToPage(this.props.navigation,"ComingSoonPage")
  }
  // Customizable Area End
}
