import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  styled,
  Button,
  withStyles
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExecutiveAssessmentController,{ Props, FaQResponse,StepsData,} from "./ExecutiveAssessmentController";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from "@material-ui/core/StepConnector";
import { profileIcon, logo, faqImg } from "../assets";
const configJSON = require("./config");
// Customizable Area End

export default class ExecutiveAccountCreation extends ExecutiveAssessmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStepper = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={5} lg={5}>
        <Box className="leftSideAccountImageContainer">
                    <ImageStyledLogoBox src={logo} alt="Logo" style={{cursor:"pointer"}} onClick={this.goToLandingPage} data-testId="goToLandingPageTest"/>
                    <ImageStyledBox src={profileIcon} alt="Login Screen" />
                    <Box className="leftSideAccountImageTextContainer">
                  <p className="accountSideonimageText">Unlock Your Potential,<br />
                  Ignite Personal Growth</p>
                </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box className={`accountCreationContent ${this.state.activeStepIndex==0?"":"overflow-scroll"}`}>
            <Typography className="accountCreationHeading">
              {configJSON.accountCreationTitlename}
            </Typography>
            <Typography className="accountCreationSubHeading">
              {configJSON.accountsubTitleName}
            </Typography>
            <Box>
              <Stepper
                activeStep={this.state.activeStepIndex}
                orientation="vertical"
                connector={<ColorlibConnector />}
                className="stepperContainer"
              >
                {[{
                  label: "Getting to know you", subHeading: this.state.activeStepIndex >= 1 ? "Completed":"About 2-3 mins",
                },
                {
                  label: "Create your Account",
                  subHeading: this.state.activeStepIndex >= 2 ? "Completed" : "About 1-2 mins",
                },
                {
                  label: "Select your coach",
                  subHeading: this.state.activeStepIndex >= 3 ? "Completed" : "About 2-3 mins",
                },
                ].map(
                  (item: StepsData, index: number) => {
                    return (
                      <Step>
                        <StepLabel>
                          <Typography className="stepMainLabel">
                            {item.label}
                          </Typography>
                          <Typography className="stepSubHeading">
                            {item.subHeading}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  }
                )}
              </Stepper>
            </Box>
            <Box>
          <Button data-testId="startButtonTestId" onClick={this.handleStartButton} className="startButton">Start</Button>
          </Box>
          </Box>
        </Grid>
      </>
    )
  }

  renderFAQ = () => {
    return (
      <Box className="faqContainer">
        <img src={faqImg} alt="faq" className="faqImg" />
        {this.state.faqQuestionData.map((faqQuestion: FaQResponse) => {
          return (<Box className="faqContent">
            <Accordion
              square
              className="accordianMain"
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className="accordianHeading">
                  {faqQuestion.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordianDetails">
                  {faqQuestion.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          )
        })}
      </Box>
    )
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <AccountCreationContainer>
        <Grid container>
          {this.renderStepper()}
          {this.state.activeStepIndex == 0&&<Grid item xs={12} sm={12} md={12} lg={12}>
             {this.renderFAQ()}  
          </Grid>}
          

        </Grid>
      </AccountCreationContainer>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundColor:"#24E09C"
    }
  },
  completed: {
    "& $line": {
      backgroundColor:"#24E09C"
    }
  },
  line: {
    flexDirection: "column",
    height: 20,
    width: 4,
    border: 0,
    background: "#EDEFEE",
    borderRadius: 1
  }
})(StepConnector);

const ImageStyledLogoBox = styled("img")({
  height:"73px",
  width:"69.00px",
  top:"20px",
  position:"absolute",
  left:"22px"
})

const ImageStyledBox = styled("img")({
  objectFit: "cover",
  width: "100%",
  borderRadius: "0px 80px 80px 0px",
  height: "100vh",
  "@media (max-width: 900px)": {
      width: '100% !important',
      objectFit: "cover",
      height: "100vh"
  },
})

const AccountCreationContainer = styled(Box)({
  width: "100%",
  height: "100vh",
  overflowX:"hidden",
  "& .heroiImg": {
    width: "100%",
    objectFit:"fill",
    height: "100vh",
    "@media screen and (min-width: 360px) and (max-width: 959px)": {
      height:"100%"
    },
  },
  "& .accountSideonimageText":{
    color: "#EDEFEE",
    textAlign: "center",
    width: "85%",
    fontFamily: "open sans",
    fontWeight: 700,
    fontSize: "34px",
    "@media(max-width:1078px)": {
      fontSize: "30px",
    },
    "@media(max-width:601px)": {
      fontSize: "24px",
    },
    "@media(max-width:450px)": {
      fontSize: "20px",
    },
    "@media(max-width:374px)": {
      fontSize: "17px",
    },
    "@media(max-width:335px)": {
      fontSize: "16px",
    }
  },
  "& .MuiIconButton-root":{
    color: "#390689"
  },
  "& .leftSideAccountImageContainer":{
    width: "100%",
    position: "relative",
    height: "auto",
  },
  "& .leftSideAccountImageTextContainer":{
    justifyContent: "center",
    margin: "auto",
    left: "5%",
    position: "absolute",
    bottom: "40px",
    color: "#EDEFEE",
    width: "100%",
    "@media(max-width:414px)": {
      left: "7%",
    },
    "@media(max-width:374px)": {
      left: "7%",
    },
  },
  
  "& .startButton":{
    backgroundColor: "#24E09C",
    borderRadius: "30px",
    fontSize: "22px",
    display:"flex",
    margin:"auto",
    color: "#FFFFFF",
    marginTop: "40px",
    marginBottom: "34px",
    fontFamily: "Open Sans",
    fontWeight: 700, 
    lineHeight: "24px",
    height: "44px",
    width: "200px",
    textTransform: "capitalize",
  },
  "& .accountCreationContent": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  "& .overflow-scroll":{
    height:"100vh",
    overflow:"auto",
    "@media(max-width:960px)":{
      height:"100%"
    }
  },
  "& .accountCreationHeading": {
    color: "#390689",
    textAlign: "center",
    fontFamily: "Martian Mono",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "50px",
    margin: "40px 50px 0px 50px",
    "@media(max-width:1274px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
    "@media(max-width:1048px)": {
      fontSize: "26px",
      lineHeight: "30px",
    },
    "@media(max-width:576px)": {
      margin: "23px 20px 0px 20px",
      fontSize: "22px",
      lineHeight: "30px",
    },
    "@media(max-width:360px)": {
      margin: "23px 10px 0px 10px",
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
  "& .accountCreationSubHeading": {
    color: "#390689",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28.332px",
    marginTop: "40px",
    paddingLeft: "90px",
    paddingRight: "90px",
    "@media(max-width:1274px)": {
      fontSize: "22px",
      lineHeight: "25px",
    },
    "@media(max-width:576px)": {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "30px",
      paddingLeft: "40px",
    paddingRight: "40px",
    },
    "@media(max-width:360px)": {
      marginTop: "15px",
      fontSize: "15px",
      lineHeight: "30px",
      paddingLeft: "25px",
      paddingRight: "25px",
    },
  },
  "& .stepperContainer": {
    "&.MuiStepper-vertical": {
      width: "420px",
      "@media(max-width:576px)": {
        width: "auto",
      },
    },
    "& .MuiStepLabel-root": {
      display: "flex",
      alignItems: "flex-start",
      "@media(max-width:377px)": {
        height:"48px"
      },
      "@media(max-width:360px)": {
        height:"48px"
      },
    },
    "& .MuiStepLabel-iconContainer": {
      color: "#EDEFEE",
      padding: "8px 8px",
      background: "#EDEFEE",
      borderRadius: "50%",
      marginRight: " 8px",
    },
    "& .MuiStepConnector-lineVertical": {
      minHeight: "85px",
      margin: "-16px 0px -12px 6px",
    },
    "& .MuiStepIcon-text": {
      display: "none ",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#24E09C",
    },
   '& .MuiStepLabel-root.MuiStepLabel-vertical.Mui-disabled .MuiStepIcon-root': {
      color: 'transparent'
  },
  '& .MuiStepConnector-vertical': {
  },
  "& .MuiStepIcon-root":{
          color:"#24E09C"
    },
    "& .MuiStepLabel-completed": {
      "& .MuiStepConnector-line": {
        borderColor: "#EDEFEE",
      },
    },

    "& .MuiStepIcon-root.MuiStepIcon-active": {
      width: "25px",
      height: "25px",
      color: "#24E09C",
      borderRadius: " 50%",
    },
  },
  "& .stepMainLabel": {
    color: "#390689",
    textAlign: "start",
    fontFamily: "Martian Mono",
    fontSize: "20.776px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28.332px",
    marginTop: "-5px",
  },
  "& .MuiAccordionDetails-root":{
    justifyContent:"center"
  },
  "& .stepSubHeading": {
    color: "#390689",
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28.332px",
  },
  "& .faqContainer": {
    background: "#EDEFEE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "60px",
    "@media(max-width:576px)": {
      padding: "20px",
    },
  },
  "& .faqContent": {
    width: "75%", 
    marginBottom :"40px",
    "@media(max-width:959px)": {
      width: "80%",
    },

    "@media(max-width:576px)": {
      width: "100%",
    },
    "@media(max-width:360px)": {
      width: "100%",
    },
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderBottom: "1px solid #A666FF",
  },
  "& .MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
    borderRadius: "20px",
  },

  "& .MuiAccordion-root.Mui-expanded": {
    border: "1px solid #A666FF",
  },
  "& .MuiAccordion-root": {
    border: "1px solid #A666FF",
    borderRadius: "12px",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  "& .accordianHeading": {
    textAlign: "center",
    color: "#390689",
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  "& .accordianDetails": {
    color: "#390689",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "34.59px 0px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  "& .faqImg": {
    width: "239px",
    height: "125px",
    marginBottom:"51px",
    "@media(max-width:576px)": {
      width: "150px",
      height: "125px",
    },
  },  
});
// Customizable Area End
