import React from 'react';
import { BlockComponent } from '../../framework/src/BlockComponent';
import {
  styled,
  Box,
  Switch,
  Divider
} from "@material-ui/core";
import moment from 'moment';
import LogoutModal from "../src/LogoutModal.web";
import { getStorageData } from 'framework/src/Utilities';

const images = require("./assets")

interface S {
  openProfileModal: boolean,
  showLogoutModal: boolean,
  isChecked: boolean
}

interface SS {

}

interface Props {
  profileImage: any,
  profileName: string,
  roleName: string,
  handleSettingNavigation : ()=> void,
  showLogoutModal: boolean,
  handleLogoutUser: any,
  handleLogoutClose: ()=> void,
  handleOpenLogoutModal: () => void,
  handleViewprofileNavigation: () => void,
  handleHelpCenterNavigation: () => void,
  showOption?: boolean,
  handleWalletNavigation?: () => void, 
}

export default class TopBar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openProfileModal: false,
      showLogoutModal: false,
      isChecked: true,
    };
  }

  handleOpenProfile = () => {
    this.setState({
      openProfileModal: !this.state.openProfileModal,
      isChecked:true,
    })
  }

  toggleChecked = () => {
    this.setState((prevState)=>({
        isChecked: !prevState.isChecked}));
};
  handleWalletClick = () => {
    if (this.props.handleWalletNavigation) {
      this.props.handleWalletNavigation();
    } 
  };
  render() {
    const currentDate = moment().format('D MMMM YYYY');
    const currentDay = moment().format('dddd');
    const userType = localStorage.getItem('userType');
    const walletBalance = localStorage.getItem("walletBalance")
    return (
      <>
        <MainDiv >
          <span className='dateSection'>
            {currentDay}, {currentDate}
          </span>

          <div className='profileSection'>
            <img src={this.props.profileImage} className='profileImage' />
            <div className='profileName'>
              {`Hi, ${userType === "coach" ? "Coach" : this.props.profileName}`} 
            </div>
            <div>
              {
                this.state.openProfileModal ?
                  <img src={images.upIconTopbar} className='arrow' onClick={this.handleOpenProfile} /> :
                  <img src={images.downIconTopbar} className='arrow' onClick={this.handleOpenProfile} />
              }
            </div>
          </div>

          {
            this.state.openProfileModal &&
            <Box
              className='profileBox'
            > 
              <Box className='secondaryProfileBox'>
                <Box className='avatarBox'>
                  <Box>
                    <img src={this.props.profileImage} className='avatarStyle' />
                  </Box>
                  <Box>
                    <Box className="textMenuColor">
                      {this.props.profileName}
                    </Box>
                    <Box className='roleText'>
                      {userType === "coach" ? "Coach" : "Coachee"}
                    </Box>
                  </Box>
                </Box>
                <Box style={{display : this.props?.showOption ? "none" :"block"}}>
                <Box
                  className="subTOPmenuPdT"
                >
                  Available
                  <Switch
                    className='switch'
                    checked={this.state.isChecked}
                    color="primary"
                    onChange={this.toggleChecked}
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box
                  className="subTOPmenuPdT"
                  onClick={this.props.handleViewprofileNavigation}
                >
                  <img
                    src={images.viewProfileIcon}
                    className='marginRight' />
                  View Profile
                </Box>
                <Box
                  className="subTOPmenuPdT"
                  onClick={this.props.handleSettingNavigation}
                >
                  <img src={images.settings} className='marginRight' />
                  Settings
                </Box>
                <Box
                  className="subTOPmenuPdT"
                  onClick={this.props.handleHelpCenterNavigation}
                >
                  <img src={images.helpcenter} className='marginRight' />
                  Help Centre
                </Box>
                    {userType === "executive" && (
                      <Box  style={{   alignItems: "center", justifyContent:'space-between',}} className='subTOPmenuPdT' onClick={this.props.handleWalletNavigation}>
                        <Box style={{ display: 'flex', justifyTracks: "flex-start", alignItems: 'center', gap: '0.15rem' }}>
                          <img src={images.wallet} className='marginRight' />
                          <Box className='walletLabel' >Wallet</Box>
                        </Box>
                        <Box className='walletAmount'>
                          {walletBalance} <span style={{ fontWeight: 700 }} className='currencyIcon'>SR</span>
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
              <Divider className='dividers' />
              <Box
                className="logoutBox subTOPmenuPdT"
                onClick={this.props.handleOpenLogoutModal}
              >
                <img src={images.logout} className='marginRight' />
                Logout
              </Box>
            </Box>
          }
            <LogoutModal showLogoutModal={this.props.showLogoutModal} logoutModalClose={this.props.handleLogoutClose} logoutUser={this.props.handleLogoutUser} />
        </MainDiv>
      </>
    )
  }
}

const MainDiv = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  top: 0,
  position: "relative",
  paddingTop: "20px",
  paddingBottom: "20px",
  "@media (max-width: 710px)": {
    marginLeft: "10px"
  },
  '& .walletAmount': {
    fontWeight: 700,
    fontFamily: "Open Sans",
    fontSize: "16px",
  
  },
  '& .dateSection': {
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontSize: "18px",
    color: "#390689",
    "@media (max-width: 480px)": {
      fontSize: "16px !important",
      width :"57% !important",
      paddingRight: "15px"
    },
  },
  '& .profileSection': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px"
  },
  '& .profileImage': {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    backgroundRepeat: "no-repeat"
  },
  '& .arrow': {
    cursor: "pointer"
  },
  '& .profileBox': {
  height: "auto",
    width: "302px",
    background: "white",
    position: "absolute",
    zIndex: 5,
    right: 0,
    top: 75,
    border: "1px solid #E2E8F0",
    borderRadius: "40px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    "@media (max-width: 480px)": {
      right: "0 !important",
      top: "100 !important",
    }
  },
  '& .secondaryProfileBox': {
    textAlign: "left",
    padding: '20px',
  },
  '& .profileName': {
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontSize: "18px",
    color: "#390689",
    "@media (max-width: 480px)": {
      fontSize: "16px !important"
    },
  },
  '& .avatarBox': {
    display: "flex"
  },
  '& .avatarStyle': {
    width: 45,
    cursor: "pointer",
    marginRight: "10px",
    height: 45,
    borderRadius: "50%",
    objectFit:'cover',
    objectPosition:'center',
    backgroundRepeat: "no-repeat"
  },
  '& .dividers': {
    background: "#0000001A"
  },
  '& .textMenuColor': {
    border: "1px solid #0000001",
    cursor: "pointer"
  },
  '& .roleText': {
    color: "#390689",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  '& .subTOPmenuPdT': {
    cursor: "pointer",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  '& .logoutBox': {
    paddingTop: '20px',
    paddingLeft: "25px",
    paddingBottom: "20px"
  },
  '& .marginRight': {
    marginRight: "10px"
  },
  '& .switch': {
    "& .MuiSwitch-thumb": {
      marginTop: "3px !important",
      marginLeft: "2px !important",
      width: "13px !important",
      height: "13px !important"
    },
    "& .MuiSwitch-root": {
      width: "58px !important",
      height: "42px !important"
    },
    "& .MuiSwitch-track": {
      borderRadius: "50px !important"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#7F3BE9"
    }
  }
})


