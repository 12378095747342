import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  withStyles,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  styled,
  Divider
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { dropDown, group } from "./assets";
import TopBar from "../../../components/src/TopBar.web";
import Sidebar from "../../../components/src/Sidebar.web";
// Customizable Area End


// Customizable Area Start

import MyClientAssessment from "../../../components/src/MyClientCoach/MyClientAssessment.web";
import Spinner from "../../../components/src/ProjectLoader.web";
// Customizable Area End

import SurveyAssessmentController, {
    Props,
    configJSON
} from "./SurveyAssessmentController.web";

export default class SurveyAssessment extends SurveyAssessmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    recommendedCoachview = (classes: { [key: string]: string }) => {
      return (
        <Grid container className={classes.recommendedMaingrid}>
         { this.state.recommendedCoachData.length>0&&!this.state.isLoading&&<><Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.recommendedgrid}
          >
            <Typography className={classes.topCoachtext}>
              {configJSON.topCoach}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.recommendedMgrid}
          >
            <Typography
              data-testid="topCoachselect"
              className={classes.topCoachselect}
            >
              {configJSON.topCoachselect}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.coachContainer}
          >
            <Box style={{ width: "min(1150px,100%)", margin: "60px auto" }}>
              <RecommendedGrid data-test-id="recommended-grid-data" container spacing={3}>
                {this.state.recommendedCoachData
                  ?.slice(0, this.state.visibleCount)
                  ?.map((data, dataIndex) => (
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                      key={data.id}
                    >
                      <Box
                        className="main-container"
                        data-test-id="recommededCoachcard"
                        onClick={() =>
                          this.navigationCoachprofile(data.attributes.id)
                        }
                      >
                        {dataIndex < 3 && <Box className="top-fit">Top fit</Box>}
                        <img
                          src={data.attributes.profile_pic}
                          alt="user-image"
                          className="user-image"
                        />
                        <Box className="overlayColor"></Box>
                        <Box className="text-container">
                          <Box>
                            <Typography
                              className="user-name"
                              title={data.attributes.full_name}
                            >
                              {data.attributes.full_name}
                            </Typography>
                            <Typography
                              className="certificate-text"
                              title={`${data?.attributes?.qualifications?.data.attributes?.certificate_name} & ${data?.attributes?.experience} years`}
                            >
                              {`${data?.attributes?.qualifications?.data.attributes?.certificate_name} & ${data?.attributes?.experience} years`}
                            </Typography>
                          </Box>
                          <Box className="redirect-button">
                            <img
                              src={group}
                              className="redirect"
                              alt="redirect"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </RecommendedGrid>
            </Box>
          </Grid>
  
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.recommendedgrid}
          >
            {(this.state.visibleCount <= 5 ||
              this.state.recommendedCoachData?.length <= 5) && (
              <Button
                className={classes.showMorebutton}
                data-testid="showMore"
                onClick={this.showMore}
              >
                {configJSON.showMore}
              </Button>
            )}
          </Grid></>}
          {
            this.state.recommendedCoachData.length==0&&!this.state.isLoading&&
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12} style={{ "height": "300px",
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center"}} className="no-data-found">
              <Typography style={{"color": "#390689",
  "fontSize": "16px",
  "fontFamily": "Open Sans",
  "fontWeight": 600,
  "lineHeight": "30px",textAlign:"center"}}>Few coaches have been removed from the system, please take a new survey to book a chemistry call.</Typography>
            </Grid>
          }
        </Grid>
      );
    };
  
    renderOptionText = (options_text:string) =>{
      return options_text?options_text:"No details added";
    }
  
    renderSurveyAnswer = (attributes:string[]) =>{
     return attributes.map((response,index) => (
        <React.Fragment key={index}>
          {response &&
          response.includes("<p>") ? (
            <div
              dangerouslySetInnerHTML={{
                __html: response,
              }}
            />
          ) : (
            <div> {this.renderOptionText(response)}</div>
          )}
        </React.Fragment>
      ))
    }
    surveyResultsscreen = (classes: { [key: string]: string }) => {
      return (
        <>
          <MainHeaderBox>
            <Breadcrumbs
              separator={
                <ChevronRightIcon style={{ height: "20px", width: "20px" }} />
              }
              aria-label="breadcrumb"
              className={classes.homeText}
            >
              <Typography
                onClick={this.handleSurvey}
                style={{ cursor: "pointer" }}
                className={classes.mainHeading}
              >
                {configJSON.Surveys}
              </Typography>
              {this.state.recommendedCoach ? (
                <Typography
                  color="textPrimary"
                  className={classes.mainHeading}
                  style={{ fontWeight: 500 }}
                >
                  {configJSON.RecommendedCoach}
                </Typography>
              ) : (
                <Typography
                  color="textPrimary"
                  className={classes.mainHeading}
                  style={{ fontWeight: 500 }}
                >
                  {configJSON.SurveysResults}
                </Typography>
              )}
            </Breadcrumbs>
          </MainHeaderBox>
          <Box className={classes.selectGridresult}>
            <Typography
              className={
                this.state.resultsScreen
                  ? classes.SurveyTextactive
                  : classes.SurveyText
              }
              data-testid="suvreyResults"
              onClick={this.handleResults}
            >
              {configJSON.SurveysResults}
            </Typography>
            <Typography
              className={
                this.state.recommendedCoach
                  ? classes.AssessmentTextactive
                  : classes.AssessmentText
              }
              data-testid="recommededCoach"
              onClick={this.handleRecommendedCoach}
            >
              {configJSON.RecommendedCoach}
            </Typography>
          </Box>
          {this.state.resultsScreen && (
            <Box
              style={{
                margin: "30px 0",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {this.state.surveyResultsdata.map(({ questions,answers },index) => {
                return (
                  <Accordion key={index} className={classes.accordion}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={
                        <img
                          src={dropDown}
                          alt="dropdown"
                          className={classes.expandIcon}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        data-testid="accordionHeading"
                        className={classes.accordionheading}
                      >
                        {questions}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetailsStyle>
                      <Box className="accordion-details">
                        {answers.length>0?this.renderSurveyAnswer(answers):"No details added"}
                      </Box>
                    </AccordionDetailsStyle>
                  </Accordion>
                );
              })}
            </Box>
          )}
          {this.state.recommendedCoach && this.recommendedCoachview(classes)}
        </>
      );
    };
  
    handleSurveyList = (classes: { [key: string]: string }) => {
      return (
        <Grid container spacing={3} className="survey-grid">
          {this.state.surveryData?.map((data, index: number) => {
            const { name, created_at } = data;
            return (
              <Grid
                key={index}
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                onMouseEnter={() => {
                  this.handlesurveyHover(index);
                }}
                onMouseLeave={this.handleSurveyLeave}
                data-testid="surveyResults"
                onClick={()=>this.handleSurveyresults(data.id)}
              >
                <Box
                  className={
                    this.state.isHoveredsurvey === index
                      ? classes.surveyGridactive
                      : classes.surveyGrid
                  }
                >
                  <Typography
                    className={
                      this.state.isHoveredsurvey === index
                        ? classes.surveyTextactive
                        : classes.surveyText
                    }
                  >
                    {name}
                  </Typography>
                  <Typography
                    className={
                      this.state.isHoveredsurvey === index
                        ? classes.surveyNameactive
                        : classes.surveyName
                    }
                  >
                    {name}
                  </Typography>
                  <Typography
                    className={
                      this.state.isHoveredsurvey === index
                        ? classes.surveyDateactive
                        : classes.surveyDate
                    }
                  >
                    {this.handleTimeZone(created_at)}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box
        style={{
          background: "#F5F5F5",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Spinner spinnerModal={this.state.isLoading} />
        <Box>
          <Box style={{ display: "flex" }}>
            <Box style={{ position: "relative", zIndex: 3 }}>
              <Sidebar
                clickSideBar="DashboardEmpty"
                expand={this.state.sidebarExpanded}
                onToggle={this.handleSidebarToggle}
                data-test-id="sidebar"
              />
            </Box>
            <BoxProfileHeadersStyle
              style={{
                width: this.state.sidebarExpanded
                  ? "calc(100% - 288px)"
                  : "calc(100% - 70px)",
                flex: 1,
              }}
            >
              <TopBarBox className="top-navbar">
                <TopBar
                  profileName={this.state.profileDetails.full_name}
                  roleName={"Client"}
                  profileImage={this.state.profileDetails.profile_pic}
                  showLogoutModal={this.state.showModal}
                  handleOpenLogoutModal={this.handleLogoutModalOpen}
                  handleLogoutClose={this.handleLogoutClose}
                  handleLogoutUser={this.handleLogoutUser}
                  handleSettingNavigation={this.handleSettingNavigation}
                  handleHelpCenterNavigation={this.handleHelpCenterNavigation}
                  handleViewprofileNavigation={this.handleViewProfile}
                  data-test-id="topbarTest"
                  handleWalletNavigation={this.handleWallletNavigation} 
                />
              </TopBarBox>
              <Divider style={{ color: "#F0E5FF" }}/>
              <Box className={classes.mainBox}>
                {!this.state.surveyResultsScreen && (
                  <>
                    <MainHeaderBox>
                      <Breadcrumbs
                        separator={
                          <ChevronRightIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                        }
                        aria-label="breadcrumb"
                        className={classes.homeText}
                      >
                        <Typography
                          onClick={() =>
                            this.state.breadCrumb1 && this.handleSurvey()
                          }
                          className={classes.mainHeading}
                          data-test-id="breadCrumb"
                          style={{
                            cursor: this.state.breadCrumb1
                              ? "pointer"
                              : "unset",
                          }}
                        >
                          {configJSON.Surveys}
                        </Typography>
                        {this.state.breadCrumb1 && (
                          <Typography
                            className={classes.mainHeading}
                            style={{
                              cursor: this.state.breadCrumb2
                                ? "pointer"
                                : "unset",
                              fontWeight: this.state.breadCrumb2 ? 400 : 500,
                            }}
                            data-test-id="breadCrumb1"
                            onClick={() =>
                              this.state.breadCrumb2 &&
                              this.handleBreadCrumbClick()
                            }
                          >
                            {this.state.breadCrumb1}
                          </Typography>
                        )}
                        {this.state.breadCrumb2 && (
                          <Typography
                            className={classes.mainHeading}
                            style={{ cursor: "unset" }}
                          >
                            {this.state.breadCrumb2}
                          </Typography>
                        )}
                      </Breadcrumbs>
                      {this.state.surveyScreen && (
                        <Button
                          className="survey-button"
                          data-test-id="survey-button"
                          onClick={() => this.redirectToSurveyPage()}
                        >
                          {configJSON.ButtonText}
                        </Button>
                      )}
                    </MainHeaderBox>
                    <Box className={classes.selectGrid}>
                      <Typography
                        className={
                          this.state.surveyScreen
                            ? classes.SurveyTextactive
                            : classes.SurveyText
                        }
                        data-testid="surveyScreen"
                        onClick={this.handleSurvey}
                      >
                        {configJSON.Surveys}
                      </Typography>
                      <Typography
                        className={
                          this.state.AssessmentScreen
                            ? classes.AssessmentTextactive
                            : classes.AssessmentText
                        }
                        data-test-id="assessmentScreen"
                        onClick={this.handleAssessment}
                      >
                        {configJSON.Assessments}
                      </Typography>
                    </Box>
                    <Box style={{ margin: "30px 0" }}>
                      {this.state.surveyScreen &&
                        this.handleSurveyList(classes)}
                      {this.state.AssessmentScreen && (
                        <MyClientAssessment
                          data-test-id="my-client-assessment"
                          assessmentList={this.state.assessmentList}
                          dateFormat={this.dateFormat}
                          dateFormatYear={this.dateFormatYear}
                          downloadFileFromURL={this.downloadFileFromURL}
                          isViewAssessmentDetailsOpen={
                            this.state.isViewAssessmentDetailsOpen
                          }
                          updateAssessmentStatus={this.updateAssessmentStatus}
                          openViewAssessmentModal={this.openViewAssessmentModal}
                          submitAssignAssessmentDetails={
                            this.uploadResultAssessment
                          }
                          closeViewAssessmentModal={
                            this.closeViewAssessmentModal
                          }
                          singleAssessment={this.state.singleAssessment}
                        />
                      )}
                    </Box>
                  </>
                )}
                {this.state.surveyResultsScreen &&
                  this.surveyResultsscreen(classes)}
              </Box>
            </BoxProfileHeadersStyle>
          </Box>
        </Box>
      </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const RecommendedGrid = styled(Grid)(({theme})=>({
  "& .main-container": {
    overflow: "hidden",
    boxShadow: "0px 2px 8px 0px #00000014",
    position: "relative",
    height: "370px",
    cursor: "pointer",
    borderRadius: "28px",
    "&:hover": {
      "& .overlayColor": {
        display: "block",
      },
      "& .text-container": {
        backgroundColor: "transparent",
      },
      "& .user-name": {
        color: "#FFF",
      },
      "& .certificate-text": {
        color: "#FFF",
      },
      "& .redirect-button": {
        display: "flex",
      },
    },
  },
  "&.MuiGrid-spacing-xs-3": {
    "@media(max-width:480px)":{
      margin: "0px",
      width:"100%",
    },
  },
  "&.MuiGrid-item": {
    "@media(max-width:480px)":{
      padding:"12px 0",
      width:"100%",
    },
  },
  "& .user-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "28px 28px 30px 30px",
  },
  "& .top-fit": {
    position: "absolute",
    zIndex: 1,
    borderRadius: "0px 10px 10px 0",
    top: "26px",
    width: "140px",
    height: "36px",
    backgroundColor: "#F46C3D",
    fontFamily: "Open Sans",
    justifyContent: "center",
    fontSize: "22px",
    display: "flex",
    fontWeight: "700",
    lineHeight: "26.4px",
    color: "#FFFFFF",
    alignItems: "center",
  },
  "& .text-container": {
    position: "absolute",
    gap: "10px",
    bottom: "0",
    left: "0",
    padding: "13px 15px 15px 20px",
    right: "0",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    transition: "0.5s ease",
  },
  "& .redirect-button": {
    height: "46px",
    width: "46px",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: "#FFF",
  },
  "& .certificate-text": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    width: "-webkit-fill-available",
    fontWeight: "600",
    "-webkit-box-orient": "vertical",
    lineHeight: "22px",
    textAlign: "left",
    color: "#390689",
    whiteSpace: "normal",
    "-webkit-line-clamp": "1",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .overlayColor": {
    top: "0",
    position: "absolute",
    transition: "0.5s ease",
    left: "0",
    width: "100%",
    height: "100%",
    display: "none",
    background:
      "linear-gradient(180deg, rgba(95, 10, 229, 0.1554) 48.81%, rgba(95, 10, 229, 0.3404) 70.77%, rgba(95, 10, 229, 0.74) 97.58%)",
  },
  "& .user-name": {
    fontFamily: "Open Sans",
    "-webkit-box-orient": "vertical",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "26px",
    textAlign: "left",
    color: "#F46C3D",
    "-webkit-line-clamp": "1",
    width: "-webkit-fill-available",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
  },
  "& .margin-60": {
    margin: "60px 0",
  },
  "& .button-container": {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));
const AccordionDetailsStyle = styled(AccordionDetails)({
  padding: "18px 20px 20px",
  "& .accordion-details":{
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: 400,
    color: "#390689",
    lineHeight: "24px",
    "& div > p": {
      margin: "0px",
    },
    "& div": {
      margin: "0px 0px 10px",
      "&:last-child":{
        marginBottom:"0px",
      }
    },
  }
});

const MainHeaderBox = styled(Box)(({ theme }) => ({
  gap: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "30px",
  flexWrap: "nowrap",
  overflowX: "auto",
  "& .MuiSvgIcon-root": {
    color: "#390689",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
    flexDirection: "column",
  },
  "& li > div": {
    whiteSpace: "nowrap",
  },
  "& .MuiBreadcrumbs-li": {
    cursor: "unset",
  },
  "& .MuiBreadcrumbs-ol": {
    flexWrap: "nowrap",
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "& .survey-button": {
    minWidth: "220px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "18px",
    borderRadius: "30px",
    backgroundColor: "#24E09C",
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "24px",
      color: "#FFF",
      textTransform: "capitalize",
    },
  },
}));
const BoxProfileHeadersStyle = styled(Box)({
  width: "100%",
  position: "relative",
  paddingBottom: "30px",
  paddingLeft: "30px",
  paddingRight: "30px",
  boxSizing: "border-box",
  "@media (max-width: 480px)": {
    padding: "10px 10px",
  },
  "& .survey-grid": {
    width: "calc(100% + 20px)",
    margin: "-10px",
    "& .MuiGrid-item": {
      padding: "10px",
    },
  },
});
const TopBarBox = styled("div")({
  width: "100%",
  "@media (max-width: 480px)": {
    paddingLeft: "30px !important",
    width: "89% !important",
    marginTop: "-8px !important",
  },
});
export const webStyle: {} = (theme: {
  breakpoints: { down: (arg0: string) => string };
}) => ({
  mainBox: {
    backgroundColor: "#F5F5F5",
    minHeight: "100vh",
  },
  mainDiv: {
    paddingRight: "30px",
    paddingLeft: "30px",
    paddingBottom: "30px",
    backgroundColor: "rgb(237, 239, 238)",
    width: "100%",
  },
  surveyMaincontainer: {
    marginBottom: "5%",
  },
  breadcrumbItem: {
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
  },
  pending: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    fontFamily: "Martian Mono",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    flexDirection: "column",
    textAlign: "center",
    alignContent: "end",
    color: "#a28cc5",
  },
  comingSoonIcon: {
    height: "100px",
    width: "100px",
    color: "#5A08E7",
    alignSelf: "center",
  },
  pendingtitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1%",
    fontFamily: "Martian Mono",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "22px",
    flexDirection: "column",
    textAlign: "center",
    alignContent: "end",
    color: "#5A08E7",
  },
  pendingSubtitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1%",
    fontFamily: "Martian Mono",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    flexDirection: "column",
    textAlign: "center",
    alignContent: "end",
    color: "#5A08E7",
  },
  breadcrumbGrid: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  breadcrumbGridresult: {
    display: "flex",
    justifyContent: "flex-start",
  },
  newsurveyGrid: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: "10%",
    },
  },
  homeText: {
    fontFamily: "Martian Mono",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#a28cc5",
    padding: "11px 0",
  },
  mainHeading: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#390689",
    lineHeight: "22px",
    fontFamily: "Martian Mono",
    cursor: "unset",
    whiteSpace: "nowrap",
  },

  selectGrid: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "30px",
  },
  selectGridresult: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "30px",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  SurveyText: {
    fontFamily: "Martian Mono",
    fontSize: "16px",
    fontWeight: 400,
    color: "#390689",
    height: "fit-content",
    width: "fit-content",
    paddingBottom: "7px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  AssessmentText: {
    fontFamily: "Martian Mono",
    fontSize: "16px",
    fontWeight: 400,
    color: "#390689",
    height: "fit-content",
    width: "fit-content",
    marginLeft: "20px",
    paddingBottom: "7px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
    },
  },
  SurveyTextactive: {
    fontFamily: "Martian Mono",
    fontSize: "16px",
    fontWeight: 700,
    color: "#6200EA",
    height: "fit-content",
    width: "fit-content",
    borderBottom: "2px solid #6200EA",
    paddingBottom: "7px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  AssessmentTextactive: {
    fontFamily: "Martian Mono",
    fontSize: "16px",
    fontWeight: 700,
    color: "#6200EA",
    height: "fit-content",
    width: "fit-content",
    marginLeft: "20px",
    borderBottom: "2px solid #6200EA",
    paddingBottom: "7px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  surveyGrid: {
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    padding: "8%",
    marginTop: "0%",
    display: "block",
    cursor: "pointer",

    [theme.breakpoints.down("xs")]: {
      padding: "10%",
    },
  },
  surveyGridactive: {
    backgroundColor: "#5F0AE5",
    borderRadius: "30px",
    padding: "8%",
    display: "block",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: "10%",
    },
  },
  surveyData: {
    [theme.breakpoints.down("xs")]: {},
  },
  surveyText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#390689",
    lineHeight: "22px",
    textAlign: "end",
    cursor: "pointer",
  },
  surveyTextactive: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#ffffff",
    lineHeight: "22px",
    textAlign: "end",
    cursor: "pointer",
  },
  surveyName: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 600,
    color: "#390689",
    lineHeight: "22px",
    marginTop: "2%",
  },
  surveyNameactive: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 600,
    color: "#ffffff",
    lineHeight: "22px",
    marginTop: "2%",
  },
  surveyDate: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    lineHeight: "22px",
    marginTop: "5%",
  },
  surveyTime: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    lineHeight: "22px",
    marginTop: "5%",
    marginLeft: "2%",
  },
  surveyDateactive: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#ffffff",
    lineHeight: "22px",
    marginTop: "5%",
  },
  surveyTimeactive: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#ffffff",
    lineHeight: "22px",
    marginTop: "5%",
    marginLeft: "2%",
  },
  resultsScreen: {
    margin: "3% 0%",

    [theme.breakpoints.down("sm")]: {
      margin: "5% 0%",
    },
  },

  accordion: {
    borderRadius: "20px !important",
    width: "100%",
    boxShadow: "none",
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0",
    },
    "&:before": {
      display: "none",
    },
  },
  expandIcon: {
    color: "#390689",
  },
  horizontalLine: {
    width: "100%",
    height: "2px",
    border: "1px solid black",
  },
  accordionSummary: {
    margin: "0px 20px",
    padding: "0px",
    "& .MuiAccordionSummary-content": {
      margin: "20px 0px ",
    },

    " &.MuiAccordionSummary-root.Mui-expanded": {
      borderBottom: "1px solid #CBD5E1",
      height: "fit-content",
    },
  },
  accordionheading: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: 600,
    color: "#390689",
    lineHeight: "24px",
  },
  detailText: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: 400,
    color: "#390689",
    lineHeight: "24px",
    "& > p ": {
      margin: "0px !important",
    },
  },
  recommendedMaingrid: {
    margin: "30px 0",
    textAlign: "center",
  },
  topCoachtext: {
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: 700,
    color: "#390689",
    lineHeight: "29px",
  },
  recommendedgrid: {
    margin: "3% 0%",
    marginBottom: "1%",
    [theme.breakpoints.down("sm")]: {
      margin: "7% 0%",
      marginBottom: "2%",
    },
  },
  topCoachselect: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    color: "#390689",
    lineHeight: "30px",
  },
  recommededDatacontainer: {
    margin: "3% 0%",
  },
  coachBox: {
    width: "min(307px,100%)",
    height: "369px",
    [theme.breakpoints.down("sm")]: {
      width: "min(450px,100%)",
    },
  },
  recommededDatagrid: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "307px",
    height: "369px",
    borderRadius: "28px",
    position: "relative",
    margin: "2% 0%",
    [theme.breakpoints.down("xs")]: {
      margin: "6% 0%",
    },
  },
  recommededDatagridactive: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "307px",
    height: "369px",
    borderRadius: "28px",
    position: "relative",
    margin: "2% 0%",
    [theme.breakpoints.down("xs")]: {
      margin: "6% 0%",
    },
  },
  recommededDatabox1: {
    position: "absolute",
    top: "10%",
    left: "0%",
    width: "fit-content",
    paddingLeft: "5%",
    background: "#F46C3D",
    paddingRight: "1%",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  showMorebutton: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 700,
    width: "fit-content",
    height: "fit-content",
    color: "#ffffff",
    lineHeight: "24px",
    borderRadius: "30px",
    backgroundColor: "#24E09C",
    padding: "10px 25px",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: 700,
      width: "fit-content",
      height: "fit-content",
      color: "#ffffff",
      lineHeight: "24px",
      borderRadius: "30px",
      backgroundColor: "#24E09C",
      padding: "10px 25px",
      textTransform: "capitalize",
      cursor: "pointer",
    },
  },
});

const CoachSectionContainer = styled(Box)({
  width: "100%",
  height: "100%",
  "& .recommdedcoachCard": {
    cursor: "pointer",
    position: "relative",
    height: "100%",
    borderRadius: "28.675px",
    overflow: "hidden",
    margin: "auto",
    textAlign: "left",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    "&:hover .overlayColor": {
      display: "block",
    },
    "&:hover .recommdedcoachInformation": {
      background: "none",
    },
    "&:hover .recommdedcoachInforight": {
      display: "block",
      width: "54px",
      height: "54px",
    },
    "@media(max-width:576px)": {
      width: "100%",
    },
  },

  "& .recommededcoachImg": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "& .recommededcoachCardTag": {
    position: "absolute",
    top: "38px",
    height: "42px",
    borderRadius: "0px 10px 10px 0px",
    background: "#F46C3D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: " 22px",
    fontStyle: " normal",
    fontWeight: " 700",
    zIndex: 1,
    padding: "0% 5%",
  },

  "& .overlayColor": {
    background:
      "linear-gradient(180deg, rgba(95, 10, 229, 0.16) 48.81%, rgba(95, 10, 229, 0.34) 70.77%, rgba(95, 10, 229, 0.74) 97.58%)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "none",
    transition: " 0.5s ease",
    top: 0,
    left: 0,
  },
  "& .recommdedcoachInformation": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    padding: "17px 30px 30px 30px",
    background: "#fff",
    transition: " 0.5s ease",
    width: "100%",
    boxSizing: "border-box",
  },
  "& .recommdedcoachInfoLeft": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .recommededName": {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 500,
    color: "#F46C3D",
    lineHeight: "26px",
  },
  "& .recommededTask": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 600,
    color: "#390689",
    lineHeight: "26px",
  },
  "& .recommededNamehover": {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 500,
    color: "#ffffff",
    lineHeight: "26px",
  },
  "& .recommededDeshover": {
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: 600,
    color: "#ffffff",
    lineHeight: "26px",
  },
  "& .groupIconbox": {
    alignSelf: "center",
  },
});

export const SurveyAssessmentStyle = withStyles(webStyle)(SurveyAssessment);

// Customizable Area End