import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Box, Typography, styled, Container } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

const arc = require("./arc.png");
const logo2 = require("./logo2.png");
const Twitter = require("./Twitter.svg");
const Instagram = require("./Instagram.svg");
const Facebook = require("./Facebook.svg");
const LinkedIn = require("./Linkedin.svg");
const mada = require("./mada.svg");
const Vector = require("./Vector.svg");
const Layer = require("./Layer.svg");
const apple = require("./apple.svg");
const Sadad = require("./Sadad.svg");
const tabby = require("./tabby.svg");
const frame = require("./Frame.svg");
const upImg = require("./up.svg");
const qrcode = require("./qrcode.png");

const socialLink = [
  { path: "https://x.com/Coachy_SA", icon: Twitter, label: "twitter" },
  {
    path: "https://www.facebook.com/profile.php?id=61558565307123",
    icon: Facebook,
    label: "facebook",
  },
  {
    path: "https://www.instagram.com/coachy.sa?igsh=MzJkNThuMWNsZWkx&utm_source=qr",
    icon: Instagram,
    label: "instagram",
  },
  { path: "https://www.linkedin.com/feed/?trk=onboarding-landing", icon: LinkedIn, label: "linkedin" },
];

interface IProps {
  navigation: any;
  topToScroll: () => void;
  navigateToContactUsPage?: () => void;
}

interface IState {}

interface ISS {}

class Footer extends BlockComponent<IProps, IState, ISS> {
  constructor(props: IProps) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  individualHandleBtn = () => {
    this.props.navigation.navigate("ExecutiveAccountCreation", { stepper: 0 });
  };

  handleJoinAsCoach = () => {
    this.props.navigation.navigate("ReadyToInspire", { activeStep: 0 });
  };

  handleFaqNavigation = () => {
    this.props.navigation.navigate("FAQScreen");
  };

  handleTermsNavigation = () => {
    this.props.navigation.navigate("TermsConditions");
  };

  handlePrivacyNavigation = () => {
    this.props.navigation.navigate("PrivacyPolicy");
  };

  handleAboutUsNavigation = () => {
    this.props.navigation.navigate("AboutUs");
  };

  otherPageToContactUs = () => {
    const msg: Message = new Message(
      getName(MessageEnum.LandingPageMessageData)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.page), "4");
    this.send(msg);
  };

  handleLinkClick = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  navigateContactUsPage = () => {
    const { navigateToContactUsPage } = this.props;
    navigateToContactUsPage
      ? navigateToContactUsPage()
      : this.otherPageToContactUs();
  };

  redirectToComingSoonPages = () => {
    this.props.navigation.navigate("ComingSoonPage");
  };

  render() {
    return (
      <>
        <FooterStyles>
          <img src={arc} className="right-half-circle" alt="Arc" />
          <Container className="footer-container">
            <Box className="footer-text-image-container">
              <img src={logo2} className="main-coachy-image" alt="Logo 2" />
              <Box className="footer-link-container">
                <Box className="quick-link-text-container">
                  <Box className="quick-link-text-container-1">
                    <Typography
                      className="footer-main-text"
                      style={{ textAlign: "left" }}
                    >
                      Quick links
                    </Typography>
                    <Box className="quick-link-text-container-1">
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        For Business
                      </Typography>
                      <Typography
                        className="quick-links"
                        data-test-id="handleGotoIndividualButton"
                        onClick={this.individualHandleBtn}
                      >
                        For Individuals
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        On Demand Coaching
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        Coaching Calculator
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.handleJoinAsCoach}
                      >
                        Join As A Coach
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="quick-link-text-container-1 for-responsive-tablet">
                    <Typography
                      className="quick-links"
                      data-test-id="faq-click"
                      onClick={this.handleFaqNavigation}
                    >
                      FAQs
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.handleTermsNavigation}
                    >
                      Legal hub
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.redirectToComingSoonPages}
                    >
                      Articles & News
                    </Typography>
                    <Typography
                      className="quick-links"
                      data-test-id="terms-click"
                      onClick={this.handleTermsNavigation}
                    >
                      Terms & Conditions
                    </Typography>
                    <Typography
                      className="quick-links"
                      data-test-id="privacy-click"
                      onClick={this.handlePrivacyNavigation}
                    >
                      Privacy Policy
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.navigateContactUsPage}
                    >
                      Contact us
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.handleAboutUsNavigation}
                      data-test-id="aboutusRedirection"
                    >
                      About us
                    </Typography>
                  </Box>
                </Box>
                <Box className="quick-link-text-container-1 for-responsive-desktop">
                  <Typography
                    className="quick-links"
                    data-test-id="faq-click"
                    onClick={this.handleFaqNavigation}
                  >
                    FAQs
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.handleTermsNavigation}
                  >
                    Legal hub
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.redirectToComingSoonPages}
                  >
                    Articles & News
                  </Typography>
                  <Typography
                    className="quick-links"
                    data-test-id="terms-click"
                    onClick={this.handleTermsNavigation}
                  >
                    Terms & Conditions
                  </Typography>
                  <Typography
                    className="quick-links"
                    data-test-id="privacy-click"
                    onClick={this.handlePrivacyNavigation}
                  >
                    Privacy Policy
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.navigateContactUsPage}
                  >
                    Contact us
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.handleAboutUsNavigation}
                    data-test-id="aboutusRedirection"
                  >
                    About us
                  </Typography>
                </Box>
                <Box className="social-link-container">
                  <Typography className="footer-main-text">
                    Social media
                  </Typography>

                  <div className="social-icon-container">
                    {socialLink.map((data) => (
                      <img
                        className="social-icon"
                        src={data.icon}
                        alt={data.label}
                        onClick={() => this.handleLinkClick(data.path)}
                      />
                    ))}
                  </div>
                </Box>
                <Box className="powered-by-container">
                  <Typography className="footer-main-text">
                    Powered by
                  </Typography>
                  <img src={frame} className="frame-image" alt="Footer Logo" />
                  <img src={qrcode} className="qr-image" alt="Footer Logo" />
                </Box>
                <Box className="top-arrow-container">
                  <div
                    data-test-id="scrollButton"
                    onClick={this.props.topToScroll}
                    className="go-to-top-container"
                  >
                    <img className="go-to-top-image" src={upImg} alt="Go Up" />
                  </div>
                </Box>
              </Box>
            </Box>
          </Container>
        </FooterStyles>
        <MainContainer>
          <Box className="afterIconArrange">
            <Box className="iconArrange">
              <img src={mada} alt="logo" />
              <img src={Vector} alt="logo" />
              <img src={Layer} alt="logo" />
              <img src={apple} alt="logo" />
              <img src={Sadad} alt="logo" />
              <img src={tabby} alt="logo" />
            </Box>
            <Box className="align-items-center">
              <span className="copy-right-text">
                &copy; 2024 Coachy. All rights reserved.
              </span>
            </Box>
          </Box>
        </MainContainer>
      </>
    );
  }
}

export default Footer;

const FooterStyles = styled(Box)(({ theme }) => ({
  backgroundColor: "#5F0AE5",
  minHeight: "479px",
  position: "relative",
  padding: "87px 0",
  boxSizing: "border-box",
  [theme.breakpoints.down("xs")]: {
    padding: "126px 0",
  },
  "& .right-half-circle": {
    position: "absolute",
    left: "0",
    top: "0",
    height: "275px",
    width: "289px",
    [theme.breakpoints.down("xs")]: {
      width: "242px",
      height: "230.28px",
    },
  },
  "& .footer-container": {
    zIndex: 1,
    position: "relative",
    "@media(min-width:1248px)": {
      maxWidth: "1248px !important",
    },
  },
  "& .footer-text-image-container": {
    display: "flex",
    gap: "157px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "96px",
    },
  },
  "& .main-coachy-image": {
    width: "94px",
    height: "72.5px",
    flex: "0 0 94px",
    maxWidth: "94px",
    maxHeight: "72.5px",
  },
  "& .footer-link-container": {
    flex: "0 0 calc(100% - (94px + 157px))",
    maxWidth: "calc(100% - (94px + 157px))",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      flex: "0 0 calc(100% - (94px + 100px))",
      maxWidth: "calc(100% - (94px + 100px))",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      maxWidth: "100%",
    },
    "@media(max-width:860px)": {
      flexDirection: "column",
      gap: "64px",
      alignItems: "center",
    },
  },
  "& .footer-main-text": {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "21.79px",
    color: "#FAFAFA",
    height: "11px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  "& .quick-links": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16.94px",
    color: "#FAFAFA",
    cursor: "pointer",
    width: "fit-content",
    height: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  "& .quick-link-text-container": {
    display: "flex",
    justifyContent: "center",
    gap: "96px",
    [theme.breakpoints.down("xs")]: {
      gap: "30px",
      justifyContent: "space-between",
      width: "100%",
      padding: "12px",
      maxWidth: "334px",
    },
  },
  "& .quick-link-text-container-1": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  "& .social-link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    maxWidth: "103px",
  },
  "& .social-icon-container": {
    display: "flex",
    gap: "32px",
    flexWrap: "wrap",
  },
  "& .social-icon": {
    height: "32px",
    width: "32px",
    minWidth: "32px",
    cursor: "pointer",
  },
  "& .powered-by-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  "& .top-arrow-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .go-to-top-container": {
    height: "48px",
    width: "48px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  "& .go-to-top-image": {
    height: "48px",
    width: "48px",
    borderRadius: "50%",
  },
  "& .frame-image": {
    height: "69px",
    width: "171px",
  },
  "& .qr-image": {
    width: "171px",
    height: "83px",
  },
  "& .for-responsive-tablet": {
    display: "none",
    marginTop: "43px",
    "@media(max-width:860px)": {
      display: "flex",
    },
  },
  "& .for-responsive-desktop": {
    marginTop: "43px",
    "@media(max-width:860px)": {
      display: "none",
    },
  },
}));

const MainContainer = styled(Container)({
  "@media(min-width:1288px)": {
    maxWidth: "1288px !important",
  },
  "& .afterIconArrange": {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    "@media (max-width: 996px)": {
      display: "flex",
      justifyContent: "center !important",
      alignItems: "center",
      alignContent: "center",
    },
    "& img": {
      width: "53.89px",
      height: "18px",
    },
  },
  "& .iconArrange": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    width: "423.42px",
    height: "18px",
    "@media (max-width: 996px)": {
      display: "none",
    },
    "@media (min-width: 998px) and (max-width:1280px)": {
      marginRight: "170px",
    },
  },
  "& .align-items-center": {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  "& .copy-right-text": {
    fontWeight: 600,
    fontSize: "12px",
    color: "#4307A0",
    fontFamily: "Open Sans",
    lineHeight: "16.34px",
  },
});
