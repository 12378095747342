import * as Yup from "yup";

const SUPPORTED_FORMATS_CERTIFICATION = [
  "application/msword",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/rtf",
  "application/pdf",
  "application/rtf",
];

const SUPPORTED_EXTENSIONS_CERTIFICATION = ["docx", "pdf", "rtf", "txt", "doc"];
const FILE_SIZE_CERTIFICATION = 2 * 1024 * 1024;

export const editBankFormValidation = Yup.object().shape({
  selected_bank: Yup.object()
    .shape({
      label: Yup.string().required("Please select bank"),
      value: Yup.string().required("Please select bank"),
    })
    .required("Please select bank"),
  bank_name: Yup.string().trim().required("Bank Name is required"),
  full_name: Yup.string()
    .matches(
      /^[A-Z][a-zA-Z]*(?: [A-Z][a-zA-Z]*)*$/,
      "Full Name must start with a capital letter and contain only letters and single spaces."
    )
    .trim()
    .required("Full Name is required"),
  iban: Yup.string()
    .when("selected_bank.value", {
      is: "international_bank",
      then: Yup.string()
        .trim()
        .matches(
          /^[A-Z]{2}\w{13,32}$/,
          "IBAN for international banks must be 15 to 34 characters long and start with 2 letters"
        ),
      otherwise: Yup.string().matches(
        /^SA\d{22}$/,
        "IBAN must start with SA and be followed by 22 digits"
      ),
    })
    .trim()
    .required("IBAN is required"),
  account_number: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string()
      .trim()
      .matches(/^\d{18}$/, "Account Number must be exactly 18 digits")
      .required("Account Number is required"),
    otherwise: Yup.string().notRequired(),
  }),
  city: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim().required("City is required"),
    otherwise: Yup.string().notRequired(),
  }),
  address: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim(),
    otherwise: Yup.string().notRequired(),
  }),
  building_number: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim(),
    otherwise: Yup.string().notRequired(),
  }),
  nick_name: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Nick Name is required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_country: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Bank Country is required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_city_branch: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Bank Country Branch is required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_address: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Bank Address is required"),
    otherwise: Yup.string().notRequired(),
  }),
  currency: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Currency is required"),
    otherwise: Yup.string().notRequired(),
  }),
  swift_code: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .min(8, "Swift Code must be 8 - 11 characters")
      .max(11, "Swift Code must be 8 - 11 characters")
      .required("Swift Code is required"),
    otherwise: Yup.string().notRequired(),
  }),
  your_address: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string().trim().required("Your Address is required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_account_doc: Yup.mixed()
    .test("is-string-or-file", "Invalid value", function (value) {
      return (
        typeof value === "string" || value === null || value instanceof File
      );
    })
    .test(
      "fileType",
      "Invalid file format. Please select .docx, .doc, .pdf, .rtf, .txt",
      function (value) {
        if (value instanceof File) {
          return SUPPORTED_FORMATS_CERTIFICATION.includes(value.type);
        }
        return true;
      }
    )
    .test(
      "fileExtension",
      "Invalid file format. Please select .docx, .doc, .pdf, .rtf, .txt",
      function (value) {
        if (value && typeof value !== "string") {
          const fileExtension = value.name.split(".").pop().toLowerCase();
          return SUPPORTED_EXTENSIONS_CERTIFICATION.includes(fileExtension);
        }
        return true;
      }
    )
    .test(
      "fileSize",
      "File size exceeds the maximum limit. Please upload a file smaller than 2 MB.",
      function (value) {
        if (value instanceof File) {
          return value.size <= FILE_SIZE_CERTIFICATION;
        }
        return true;
      }
    )
    .required("Bank Account Doc is required"),
});

export const assignAssessmentSchema = Yup.object().shape({
  clientName: Yup.string().trim().required("This field is required"),
  assessmentName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("This field is required"),
  startDate: Yup.date()
    .nullable()
    .min(
      new Date().toISOString().split("T")[0],
      "Start Date must be today or future"
    )
    .required("This field is required"),
  dueDate: Yup.date()
    .nullable()
    .min(
      Yup.ref("startDate"),
      "Due Date must be equal to or greater than future start date"
    )
    .required("This field is required"),
  description: Yup.string()
    .required("This field is required")
    .max(500, "Maximum 500 characters are allowed"),
});

const SUPPORTED_FORMATS_RESULT = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.ms-powerpoint",
];

const SUPPORTED_EXTENSIONS_RESULT = [
  "pdf",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "odp",
];

export const uploadAssessmentSchema = Yup.object().shape({
  result: Yup.mixed()
    .required("Result is required")
    .test(
      "fileType",
      "Invalid file format. Please select .docx, .doc, .pdf, .ppt, .pptx",
      (value) => value && SUPPORTED_FORMATS_RESULT.includes(value.type)
    )
    .test(
      "fileExtension",
      "Invalid file format. Please select .docx, .doc, .pdf, .ppt, .pptx",
      (value) => {
        if (value) {
          const fileExtension = value.name.split(".").pop().toLowerCase();
          console.log(fileExtension);
          return SUPPORTED_EXTENSIONS_RESULT.includes(fileExtension);
        }
        return true;
      }
    )
    .test(
      "fileSize",
      "File size exceeds the maximum limit. Please upload a file smaller than 2 MB.",
      (value) => value && value.size <= FILE_SIZE_CERTIFICATION
    ),
});


export const getValidationSchema = (minDays = 1, maxDays = 45) => {
  return Yup.object().shape({
    startDate: Yup.date()
      .nullable()
      .min(
        new Date().toISOString().split("T")[0],
        "Start Date must be today or future"
      )
      .required("Start Date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .nullable()
      .min(
        Yup.ref("startDate"),
        `End date must be at least ${minDays} day(s) after start date`
      )
      .test(
        "min-days",
        `End date must be at least ${minDays} day(s) after start date`,
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) return true; 
          const minDate = new Date(startDate);
          minDate.setDate(minDate.getDate() + minDays);
          const adjustedMinDate = new Date(minDate.setHours(0, 0, 0, 0));
          const endDate = new Date(value.setHours(0, 0, 0, 0));
          return endDate >= adjustedMinDate;
        }
      )
      .test(
        "max-days",
        `End date must not be more than ${maxDays} day(s) after start date`,
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) return true;
          const maxDate = new Date(startDate);
          maxDate.setDate(maxDate.getDate() + maxDays);
          const adjustedMaxDate = new Date(maxDate.setHours(0, 0, 0, 0));
          const endDate = new Date(value.setHours(0, 0, 0, 0));
          return endDate <= adjustedMaxDate; 
        }
      ),
  });
};
