import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";

export interface FaQResponse {
    message:[];
    id: number;
    question: string;
    answer: string;
}

export interface StepsData {
    label: string;
    subHeading: string;
}
// Customizable Area End

const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    activeStepIndex: number;
    faqQuestionData: Array<FaQResponse>;
    sellectAns: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ExecutiveAssessmentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    faqQuestionGetApiCallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.stepper),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            activeStepIndex: 0,
            faqQuestionData: [],
            sellectAns: "",
        };

        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseSucessCell(apiRequestCallId, responseJson)
            }
        }
        else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let stepper = message.getData(getName(MessageEnum.stepper));
            this.setState({ activeStepIndex: stepper})
           
        }
    }

    async componentDidMount() {
        this.faqQuestionGetApi()
    }

    executiveApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    faqQuestionGetApi = async () => {
        this.faqQuestionGetApiCallId = await this.executiveApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.faqQuestion,
        });
    }

    responseSucessCell = async (apiRequestCallId: string, responseJson: FaQResponse) => {
        if (apiRequestCallId === this.faqQuestionGetApiCallId) {
            this.faqQuestionSucessCallBack(responseJson);
        }
    }

    faqQuestionSucessCallBack = (res: FaQResponse) => {
        this.setState({ faqQuestionData: res.message })
    };

    goToLandingPage=()=>{
        this.props.navigation.navigate("LandingPage");
    }

    handleSellect = (sellectAnswer: string) => {
        this.setState({ sellectAns: sellectAnswer })
    }

    handleNextButton = () => {
        if(this.state.sellectAns === "For my team or my organization"){ 
            this.navigateScreens("ExecutiveContactUs")
        }else{
            this.navigateScreens("ExecutiveAssessment")
        }  
    }
    
    handleStartButton = () => {
        if (this.state.activeStepIndex == 0) {
            this.navigateScreens("ExecutiveGeneralInfo")
          } else if (this.state.activeStepIndex == 1) {
            this.navigateScreens("ExecutiveSignUp")
          } else if (this.state.activeStepIndex == 2) {
            this.navigateScreens("CoachSelection")
          }
    }

    handleCommingScreen = ()=>{
        this.navigateScreens("ExecutiveGeneralInfo")
    }

    navigateScreens = (pathName: string) => {
        const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msg);
      };

     handleBackButton = () =>{
        const message: Message = new Message(getName(MessageEnum.ExcecutiveAccountCreationScreen));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.stepper), "0");
          this.send(message);
     } 
    // Customizable Area End
}

