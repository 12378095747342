import React, { RefObject } from "react";
// Customizable Area Start
import {
  Box,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Table,
  Typography,
  styled,
  Button,
} from "@material-ui/core";
import ThreeDotDropDown from "../ThreeDotDropDownTable.web";
import { Formik } from "formik";
import { uploadAssessmentSchema } from "../schema.web";
import ErrorIcon from "@material-ui/icons/Error";

const pdfSvgIcon = require("./assets/pdf_icon.svg");
const wordSvgIcon = require("./assets/word_icon.svg");
const download = require("./assets/download.svg");
const editIcon = require("./assets/edit_icon.svg");
const uploadIcon = require("./assets/upload_icon.svg");
const pptIcon = require("./assets/ppt_icon.svg");
const odpIcon = require("./assets/odp_icon.svg");

interface IAssessmentList {
  id: string;
  type: string;
  attributes: {
    id: number;
    description: string;
    assessment_id: number;
    assessment_name: string;
    client_name: string;
    client_id: number;
    coach_id: number;
    result: null | string;
    start_date: string;
    result_status: string;
    coach_profile_pic: string;
    coach_name: string;
    due_date: string;
    status: string;
    file_size: string | null;
  };
}

interface FormValues {
  result: File | null;
}
// Customizable Area End
interface Props {
  // Customizable Area Start
  openViewAssessmentModal: (assessmentId: number) => void;
  isViewAssessmentDetailsOpen: boolean;
  submitAssignAssessmentDetails: (result: null | File) => void;
  assessmentList: IAssessmentList[];
  dateFormat: (date: string) => string;
  dateFormatYear: (date: string) => string;
  singleAssessment: IAssessmentList;
  downloadFileFromURL: (filePath: string, fileName: string) => void;
  updateAssessmentStatus: (assessmentId: number) => void;
  closeViewAssessmentModal: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
export default class MyClientAssessment extends React.Component<Props, S> {
  // Customizable Area Start
  fileInputRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFileIcon = (fileName: string) => {
    if (fileName.includes(".pdf")) {
      return (
        <img
          src={pdfSvgIcon}
          style={{ height: "16px", width: "16x", minWidth: "16px" }}
        />
      );
    } else if (fileName.includes(".docx") || fileName.includes(".doc")) {
      return (
        <img
          src={wordSvgIcon}
          style={{ height: "16px", width: "16x", minWidth: "16px" }}
        />
      );
    } else if (fileName.includes(".ppt") || fileName.includes(".pptx")) {
      return (
        <img
          src={pptIcon}
          style={{ height: "16px", width: "16x", minWidth: "16px" }}
        />
      );
    } else {
      return (
        <img
          src={odpIcon}
          style={{ height: "16px", width: "16x", minWidth: "16px" }}
        />
      );
    }
  };

  renderStatusButton = (type: string, assessmentId: number) => {
    switch (type) {
      case "assigned":
        return (
          <AssignedButton
            data-test-id="assigned-button"
            onClick={() => this.props.updateAssessmentStatus(assessmentId)}
          >
            <span className="assigned">Assigned</span>
            <span className="start"> Start</span>{" "}
            <img src={editIcon} alt="edit" className="edit-icon" />
          </AssignedButton>
        );
      case "in_progress":
        return <ProgressButton>In Progress</ProgressButton>;
      case "completed":
        return <CompletedButton>Completed</CompletedButton>;
      default:
        return (
          <AssignedButton
            onClick={() => this.props.updateAssessmentStatus(assessmentId)}
          >
            Assigned <img src={editIcon} alt="edit" className="edit-icon" />
          </AssignedButton>
        );
    }
  };

  retrieveFileUrlName = (fileUrl: string | null) => {
    let file_name = "";
    const parts = fileUrl != null && fileUrl && fileUrl.split("/");
    if (parts) {
      file_name = parts[parts.length - 1];
    }
    return file_name;
  };

  inputButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.fileInputRef.current.focus();
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { singleAssessment } = this.props;
    const fileName = this.retrieveFileUrlName(
      singleAssessment.attributes.result
    );

    return this.props.isViewAssessmentDetailsOpen ? (
      <AssessmentDetails>
        <Typography className="assessment-name">
          {singleAssessment.attributes.assessment_name}
        </Typography>
        <Box className="assessment-details">
          <Box style={{ width: "100%" }}>
            <Typography className="assessment-title">
              About Assessment
            </Typography>
            <Typography className="assessment-description">
              {singleAssessment.attributes.description}
            </Typography>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography
              className="assessment-title"
              style={{ marginBottom: "30px" }}
            >
              Assessment Details
            </Typography>
            <TableContainer className="assessment-details-table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="details-title" align="left">
                      Assigned By
                    </TableCell>
                    <TableCell className="details-title" align="left">
                      Assigned Date
                    </TableCell>
                    <TableCell className="details-title" align="left">
                      Due Date
                    </TableCell>
                    <TableCell className="details-title" align="center">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="details-description" align="left">
                      <span className="coach-name">
                        {this.props.singleAssessment.attributes.coach_name}
                      </span>
                    </TableCell>
                    <TableCell className="details-description" align="left">
                      {this.props.dateFormatYear(
                        singleAssessment.attributes.start_date
                      )}
                    </TableCell>
                    <TableCell className="details-description" align="left">
                      {this.props.dateFormatYear(
                        singleAssessment.attributes.due_date
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Box className="item-center">
                        {this.renderStatusButton(
                          singleAssessment.attributes.status,
                          singleAssessment.attributes.id
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {singleAssessment.attributes.status === "in_progress" && (
            <Formik<FormValues>
              data-test-id="assign-assessment-formik"
              validationSchema={uploadAssessmentSchema}
              enableReinitialize
              initialValues={{
                result: null,
              }}
              onSubmit={(values: { result: null | File }) => {
                this.props.submitAssignAssessmentDetails(values.result);
              }}
            >
              {({ values, errors, setFieldValue, handleSubmit, isValid }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box className="assessment-result-container">
                      <Typography className="assessment-result-text">
                        Assessment
                        <br /> Result
                      </Typography>
                      <Box className="upload-container">
                        <input
                          ref={this.fileInputRef}
                          style={{ display: "none" }}
                          data-test-id="file-upload"
                          type="file"
                          accept=".docx,.doc,.pdf,.ppt,.pptx,.odp"
                          onChange={(event) => {
                            if (event.target.files)
                              setFieldValue(
                                "result",
                                event.target.files[0]
                                  ? event.target.files[0]
                                  : values.result
                              );
                          }}
                        />
                        <Button
                          className="upload-button"
                          onClick={this.inputButtonClick}
                        >
                          <img
                            src={uploadIcon}
                            alt="upload"
                            className="upload-image"
                          />
                          Upload
                        </Button>
                        {errors.result && (
                          <ErrorTypography
                            style={{
                              marginBottom: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <ErrorIcon
                              style={{
                                height: 16,
                                width: 16,
                              }}
                            />
                            {errors.result}
                          </ErrorTypography>
                        )}
                        {values.result != null && !errors.result && (
                          <Typography className="profile-photo-filename">
                            {values.result!.name ?? ""}
                          </Typography>
                        )}
                        <Typography className="maximum-size-text">
                          Make sure the file is below 2 MB
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="button-container">
                      <Button
                        data-test-id="cancel-button"
                        className="cancel-button"
                        onClick={this.props.closeViewAssessmentModal}
                      >
                        Close
                      </Button>
                      <Button
                        className="submit-button"
                        type="submit"
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          )}

          {singleAssessment.attributes.result_status === "uploaded" &&
            singleAssessment.attributes.result && (
              <Box style={{ width: "100%" }}>
                <Typography
                  className="assessment-title"
                  style={{ marginBottom: "30px" }}
                >
                  Assessment Result
                </Typography>
                <CVContainer>
                  <div className="cv-container">
                    {this.renderFileIcon("text.pdf")}
                    <Typography
                      className="file-name"
                      title={decodeURIComponent(fileName)}
                    >
                      {decodeURIComponent(fileName)}
                    </Typography>

                    <Typography
                      className="file-size"
                      title={singleAssessment.attributes.file_size ?? ""}
                    >
                      {singleAssessment.attributes.file_size}
                    </Typography>
                    <img
                      src={download}
                      alt="download"
                      data-test-id="download"
                      onClick={() =>
                        this.props.downloadFileFromURL(
                          singleAssessment.attributes.result ?? "",
                          fileName
                        )
                      }
                      className="download-image"
                    />
                  </div>
                </CVContainer>
              </Box>
            )}
        </Box>
      </AssessmentDetails>
    ) : (
      <TableDataContainer data-test-id="client-list-data">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-data-title" align="left">
                  Assessment Name
                </TableCell>
                <TableCell className="table-data-title" align="center">
                  Date
                </TableCell>
                <TableCell className="table-data-title" align="left">
                  Assigned By
                </TableCell>
                <TableCell className="table-data-title" align="center">
                  Status
                </TableCell>
                <TableCell className="table-data-title" align="center">
                  Option
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.assessmentList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} className="no-data-text-container">
                    <Box className="item-center">
                      <Typography className="no-data-found-text">
                        No Assigned Assessment
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                this.props.assessmentList.map((data, index) => (
                  <TableRow className="data-row" key={index}>
                    <TableCell align="left">
                      <Box className="user-image-container">
                        <span
                          className="user-name"
                          title={data.attributes.assessment_name}
                        >
                          {data.attributes.assessment_name}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {this.props.dateFormat(data.attributes.start_date)} -{" "}
                      {this.props.dateFormat(data.attributes.due_date)}
                    </TableCell>
                    <TableCell align="left" className="max-width-250">
                      <Box className="user-image-container">
                        <img
                          className="user-image"
                          alt="user-img"
                          src={data.attributes.coach_profile_pic}
                        />
                        <span
                          className="user-name"
                          style={{ display: "block" }}
                          title={data.attributes.coach_name}
                        >
                          {data.attributes.coach_name}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="item-center">
                        {this.renderStatusButton(
                          data.attributes.status,
                          data.attributes.id
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="item-center">
                        <ThreeDotDropDown
                          data-test-id="menuClick"
                          dataLength={this.props.assessmentList.length}
                          index={index}
                          maxItem={8}
                          mobileMaxItem={6}
                          dropDownItemList={[
                            {
                              itemText: "View Details",
                              itemOnClick: () =>
                                this.props.openViewAssessmentModal(
                                  data.attributes.id
                                ),
                            },
                          ]}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableDataContainer>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const AssessmentDetails = styled(Box)(({ theme }) => ({
  borderRadius: "40px",
  width: "100%",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  minHeight: "700px",
  overflow: "hidden",
  "& .assessment-name": {
    padding: "20px 24px",
    textAlign: "left",
    fontFamily: "Martian Mono",
    lineHeight: "28px",
    fontSize: "22px",
    fontWeight: "700",
    backgroundColor: "#F9F5FF",
    color: "#390689",
  },
  "& .assessment-details": {
    gap: "30px",
    padding: "29px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .upload-button": {
    width: "200px",
    height: "48px",
    borderRadius: "20px",
    padding: "10px 16px 10px 16px",
    backgroundColor: "#F1F5F9",
    border: "1px solid #390689",
    "& .MuiButton-label": {
      gap: "12px",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "20px",
      color: "#64748B",
      textTransform: "capitalize",
    },
  },
  "& .assessment-title": {
    fontFamily: "Open Sans",
    marginBottom: "15px",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#390689",
    textAlign: "left",
  },
  "& .assessment-description": {
    color: "#390689B2",
    fontFamily: "Open Sans",
    fontSize: "16px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .assessment-result-text": {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#390689",
  },
  "& .maximum-size-text": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#390689",
  },
  "& .profile-photo-filename": {
    fontFamily: "Open sans",
    fontSize: "14px",
    color: "green",
    margin: "5px 0 10px",
    wordBreak: "break-word",
  },
  "& .button-container": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    margin: "30px 0",
  },
  "& .cancel-button": {
    width: "200px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "18px",
    borderRadius: "30px",
    backgroundColor: "#F1F5F9",
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "24px",
      color: "#64748B",
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "36px",
    },
  },
  "& .submit-button": {
    width: "228px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "18px",
    borderRadius: "30px",
    backgroundColor: "#24E09C",
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "24px",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
    "&.Mui-disabled": {
      backgroundColor: "#24E09C !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "36px",
    },
  },
  "& .assessment-result-container": {
    display: "flex",
    gap: "19px",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  "& .user-image": {
    height: "32px",
    width: "32px",
    minWidth: "32px",
    objectFit: "cover",
    borderRadius: "50px",
  },
  "& .assessment-details-table-container": {
    "& table": {
      minWidth: "650px",
      maxWidth: "650px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#D9D9D9",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar": {
      height: "4px",
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#979797",
      borderRadius: "4px",
    },
    "& th, & td": {
      whiteSpace: "nowrap",
      borderBottom: "none",
    },
    "& .coach-name": {
      display: "-webkit-box",
      width: "-webkit-fill-available",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
    },
    "& th:first-child, & td:first-child": {
      paddingLeft: "0px",
    },
    "& .item-center": {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    "& .details-title": {
      color: "#390689",
      fontFamily: "Open Sans",
      lineHeight: "22px",
      fontSize: "16px",
      fontWeight: "600",
    },
    "& .details-description": {
      fontFamily: "Open Sans",
      color: "#34D399",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "400",
    },
  },
}));

const TableDataContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #E2E8F0",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  borderRadius: "40px",
  backgroundColor: "#FFF",
  overflow: "hidden",
  boxSizing: "border-box",

  [theme.breakpoints.down("sm")]: {
    borderRadius: "20px",
  },
  "& .no-data-text-container": {
    height: "632px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  "& ::-webkit-scrollbar": {
    height: "4px",
    width: "4px",
  },
  "& ::-webkit-scrollbar-track": {
    borderRadius: "4px",
    background: "#D9D9D9",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#979797",
    borderRadius: "4px",
  },
  "& .MuiTableContainer-root": {
    minHeight: "700px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "468px",
    },
  },
  "& table": {
    minWidth: "800px",
  },
  "& .max-width-250": {
    maxWidth: "250px",
    width: "250px",
    boxSizing: "border-box",
  },
  "& thead > tr": {
    backgroundColor: "#F9F5FF",
    "& th:first-child": {
      paddingLeft: "30px",
      maxWidth: "250px",
      boxSizing: "border-box",
    },
    "& th": {
      padding: "23px 16px",
    },
    "& th:last-child": {
      width: "52px",
      maxWidth: "52px",
      paddingRight: "27px",
    },
  },
  "& th": {
    fontSize: "16px",
    border: "none",
    lineHeight: "22px",
    fontWeight: "600",
    fontFamily: "Open Sans",
    color: "#390689",
    [theme.breakpoints.down("sm")]: {
      padding: "15px !important",
    },
  },
  "& .user-image": {
    height: "32px",
    width: "32px",
    minWidth: "32px",
    objectFit: "cover",
    borderRadius: "50px",
  },
  "& .no-data-found-text": {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: "600",
    color: "#390689",
    lineHeight: "22px",
  },
  "& .item-center": {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  "& .no-data-found": {
    height: "333px",
    [theme.breakpoints.down("sm")]: {
      height: "275px",
    },
  },
  "& .user-image-container": {
    fontWeight: "600",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    width: "fit-content",
    maxWidth: "250px",
  },
  "& tr:last-child": {
    marginBottom: "0px",
  },
  "& td": {
    borderColor: "#CBD5E1",
    fontWeight: "400",
    fontFamily: "Open Sans",
    color: "#390689",
    padding: "17px 16px",
    fontSize: "16px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 14px !important",
    },
  },
  "& tr td:first-child": {
    paddingLeft: "30px",
    maxWidth: "200px",
  },
  "& tr td:last-child": {
    maxWidth: "52px",
    width: "52px",
    paddingRight: "26px",
  },
  "& .table-data-title": {
    whiteSpace: "nowrap",
  },
  "& .user-name": {
    "-webkit-line-clamp": "1",
    width: "-webkit-fill-available",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordBreak: "break-all",
    textAlign: "left",
    "-webkit-box-orient": "vertical",
  },
}));

const ProgressButton = styled(Box)({
  borderRadius: "20px",
  backgroundColor: "#6200EA !important",
  padding: "3px 20px",
  width: "122px",
  color: "#fff",
  boxSizing: "border-box",
  lineHeight: "22px",
  border: "1px solid #5F0AE5",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: 600,
  textTransform: "none",
});

const CompletedButton = styled(Box)({
  borderRadius: "20px",
  padding: "4px 24px 4px 24px",
  border: "1px solid #5F0AE5",
  width: "122px",
  lineHeight: "22px",
  boxSizing: "border-box",
  fontFamily: "Open Sans",
  backgroundColor: "#FFF",
  fontSize: "14px",
  fontWeight: 600,
  color: "#5F0AE5",
  textTransform: "none",
});

const AssignedButton = styled(Box)({
  borderRadius: "20px",
  height: "30px",
  width: "122px",
  textTransform: "none",
  boxSizing: "border-box",
  border: "1px solid #24E09C",
  fontFamily: "Open Sans",
  backgroundColor: "#FFF",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  color: "#24E09C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "6px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#24E09C",
    color: "#FFF",
  },
  "& .assigned": {
    display: "block",
  },
  "& .start": {
    display: "none",
  },
  "&:hover .assigned,&:hover .edit-icon": {
    display: "none",
  },
  "&:hover .start": {
    display: "block",
  },
});

const ErrorTypography = styled(Typography)({
  justifyContent: "flex-start",
  marginTop: "4px",
  color: "#DC2626",
  alignItems: "center",
  gap: "4px",
  display: "flex",
  fontSize: "12px",
  fontFamily:"Open Sans"
});

const CVContainer = styled(Box)({
  gap: "15px",
  display: "flex",
  flexWrap: "wrap",
  "& .cv-container": {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    border: "1px solid #E2E8F0",
    borderRadius: "20px",
    alignItems: "center",
    padding: "13px 22px 16px",
    width: "171px",
  },
  "& .file-name": {
    color: "#390689",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    lineHeight: "22px",
    marginTop: "8px",
    wordBreak: "break-all",
  },
  "& .file-size": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "center",
    fontFamily: "Open Sans",
    color: "#B2B2B2",
    display: "block",
    textOverflow: "ellipsis",
    marginBottom: "12px",
    overflow: "hidden",
  },
  "& .download-image": {
    cursor: "pointer",
  },
});
// Customizable Area End
